import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import '../../App.css';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';

const pages = [
    { text: 'About', href: '/about' },
    { text: 'Photos', href: '/photos' },
    { text: 'Blog', href: '/blog' },
    { text: 'Location', target: '_blank', rel: 'noreferrer', href: 'https://www.marinetraffic.com/en/ais/details/ships/shipid:5465907/mmsi:368009540/imo:0/vessel:CAPRICORN'}
  ]

export function Header2 () {

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
 
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
    console.log('in handleOpenNavMenu and it is '+ anchorElNav);
  };

  const handleCloseNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
    console.log('in handleCloseNavMenu and it is '+ anchorElNav);
    console.log('now setting to null ');
    setAnchorElNav(null);
    console.log('and now it is '+ anchorElNav);
  };

  return (
    <AppBar position='static' className='App-bar'>
      <Container maxWidth="xl">
        <Toolbar className='Header' disableGutters>
        <IconButton
              id='basic-button'
              size="medium"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
          > 
            <MenuIcon />
          </IconButton>
          <Menu
            // id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            <MenuItem onClick={(() => navigate ('/'))}>Home</MenuItem>
            <MenuItem onClick={(() => navigate ('/about'))}>About</MenuItem>
            <MenuItem onClick={(() => navigate ('/blog'))}>Blog</MenuItem>
            <MenuItem onClick={(() => navigate ('/photos'))}>Photos</MenuItem>
            <MenuItem>
              <a target='_blank' href='http://marinetraffic.com/en/ais/details/ships/shipid:5465907/mmsi:368009540/imo:0/vessel:CAPRICORN' rel='noreferrer'>
              Location
              </a>
            </MenuItem>
          </Menu>
          <Typography
          className='App-title'
          >
            <Link  className='App-title' underline="hover" variant='inherit' href='/'>
            SV Capricorn</Link>
          </Typography>
          {/* <Box className='Profile-menu'>            
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              // keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.text} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.text}
                href={page.href}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.text}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};