import * as React from 'react';
import './App.css';
import LandingPageComponent from 'components/LandingPage/LandingPageComponent';
import BlogComponent from './components/Blog/BlogComponent';
import { Routes, Route } from 'react-router-dom';
import AboutComponent from './components/About/AboutComponent';
import PhotoGalleryTileComponent from 'components/PhotoGallery/PhotoGalleryTileComponent';

export default function App(): React.ReactElement {
  
  return (
    <div>
        <Routes>
          <Route path='/' element={<LandingPageComponent />} />
          <Route path='/about' element={<AboutComponent />} />
          <Route path='/blog' element={<BlogComponent />} />
          <Route path='/photos' element={<PhotoGalleryTileComponent />} />
        </Routes>
    </div>      
  );
};
