// import * as React from 'react';
import './LandingPage.css';
import '../../App.css'
import { Card } from '@mui/material';
import { Footer } from 'components/Footer/Footer';
import { Header2 } from 'components/Header/Header2';


export default function LandingPageComponent() {

  return (
    <div>
      <Header2 />
        <Card className='Capricorn-background' />
      <Footer />

    </div>
  );
};
