import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faInstagram
  } from '@fortawesome/free-brands-svg-icons';

export default function SocialFollow() {

    let iconStyling = {
        color: '#cc0000'
    }

  return (
    <div className='social-container'>
      <div className='social-container'>
          <br/>
      <a style={iconStyling} target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/svcapricorn/'
        className='instagram social'>
        <FontAwesomeIcon icon={faInstagram} size='2x' />
      </a>
</div>
    </div>
  );
};