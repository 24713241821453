import * as React from 'react';
import { Header2 } from 'components/Header/Header2';
import SocialFollow from './SocialFollow';
import { Footer } from 'components/Footer/Footer';
import { Card, CardHeader, Typography } from '@mui/material';
import '../../App.css';

export default function AboutComponent() {

  return (
    <div className='About' >
      <Header2 />
      <Card  className='About-card'>
        <CardHeader>About S/V Capricorn</CardHeader>
          <Typography>
            Si and I have spent the last 5 years looking for our perfect bluewater boat.  We found one right before Covid Quarantine in 2020 and this boat, Iconoclast, kept coming back to us.  We will share our experiences here.  This is a work in progress so bear some patience with us while I build this out
          </Typography>
          <br/>
          <Typography>You can follow us SV/Capricorn on Instagram
          <SocialFollow />
          </Typography>
          
      </Card>
      <Footer />
    </div>
  );
};
