import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import ImageData from './ImageData';
import Card from '@mui/material/Card';
import { Header2 } from 'components/Header/Header2';
import { Footer } from 'components/Footer/Footer';

let itemData = ImageData;

export default function PhotoGalleryTileComponent() {
  return (
    <div>
      <Header2 />
      <Card>
        <ImageList >
          <ImageListItem key="Subheader" cols={2}>
            <ListSubheader component="div">2021</ListSubheader>
          </ImageListItem>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}`}
                src={`${item.img}`}
                alt={item.title}
                loading='lazy'
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about ${item.title}`}
                  >
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Card>
      <Footer />
    </div>
  );
};
