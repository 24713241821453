import * as React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

export const BASE_PATH = '/';

root.render(
<React.StrictMode>
  <BrowserRouter basename={BASE_PATH}>
    <App/>
  </BrowserRouter>
</React.StrictMode>

);
