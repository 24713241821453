import { Footer } from 'components/Footer/Footer';
import { Header2 } from 'components/Header/Header2';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import { CardHeader } from '@mui/material';


const columns: GridColDef[] = [
  // { field: 'col0', headerName: 'Id', width: 100 }, 
  { field: 'col1', headerName: 'Date', width: 100 }, 
  { field: 'col2', headerName: 'Title', width: 150 }, 
  { field: 'col3', headerName: 'Latitude', width: 150 }, 
  { field: 'col4', headerName: 'Longitude', width: 150 },    
  { field: 'col5', headerName: 'BlogText', width: 500 },
  // { field: 'col6', headerName: 'Image', width: 150, editable: true, renderCell:  (params) => <img src={params.value} /> // renderCell will render the component 
  // },
  // nest photo array here when ready { field: 'col4', headerName: 'PhotoRef', width: 150 },
  { field: 'col7', headerName: 'Author', width: 150 },
  // { field: 'col7', headerName: 'Active', width: 15 }
];
// const rows: GridRowsProp = [

const rows: GridRowsProp = [
  { id: 1,
    col1: '2021-10-07',
    col2: 'Removed the name decals',
    col3: '47.630809',
    col4: '122.31651',
    col5: 'We did some boat work... I polished some chrome yada yada, and used the hair dryer to remove the name off the transom.  Along the way (220 v), Simon took apart the extension chord and fixed it yay!',
    col7: 'Lisa Lilley',
    col8: true
  },
  { id: 2,
    col1: '2021-10-02',
    col2: 'Maiden Voyage',
    col3: '47.630809',
    col4: '122.31651',
    col5: 'Anchorage in Poulsbo--because of ease and wanting to focus on learning more about the boat.  Motorsailed and as we put the main up (electric winch really changes that... but need to keep working muscles haha... we lost lazy jacks with no stopknot.  Si was winched up and fixed it.  Let-down was not difficult either.  Yes, we had a safety.',
    col7: 'Lisa Lilley',
    col8: true
  },
  { id: 3,
    col1: '2021-10-07',
    col2: 'Removed the name decals',
    col3: '47.630809',
    col4: '122.31651',
    col5: 'We did some boat work... I polished some chrome yada yada, and used the hair dryer to remove the name off the transom.  Along the way (220 v), Simon took apart the extension chord and fixed it yay!',
    col7: 'Lisa Lilley',
    col8: true
  },
  {
    id: 4,
    col1: '2021-09-22',
    col2: 'Crew arrives with Iconoclast (to be renamed Capricorn)',
    col3: '',
    col4: '',
    col5: 'Captain Ray McCormack, Jove, Ryan, and Mark',
    col7: 'Lisa Lilley',
    col8: true
  },
  { id: 5,
    col1: '2021-09-10',
    col2: 'Closing',
    col3: '',
    col4: '',
    col5: 'Simon flew down to Marina del Rey within hours of getting a thumbs-up from seller on price after a counter-offer.',
    col7: 'Lisa Lilley',
    col8: true
  }
];

  export default function BlogComponent() {
    return (
      <div style={{ height: 300, width: '100%' }}>
        <Header2/>
        <Card>
          <CardHeader>Blog Entries for SV/Capricorn</CardHeader>
          <DataGrid rows={rows} columns={columns} />
        </Card>
        <div>
          
        </div>
        <Footer/>
      </div>
    );
  };