import beautifulTeak from './../../img/Beautiful_Teak.png';
import backed_In from './../../img/Backed_In.png';
import capn_Ray_And_Crew from './../../img/Capn_Ray_And_Crew.png';
import fuelDock from './../../img/Fuel_Dock_MdR.png';
import fullyBattened from './../../img/Fully_Battened_Mainsl_Is_Up.png';
// import beautifulTeak from './../../img/Beautiful_Teak.png';
// import beautifulTeak from './../../img/Beautiful_Teak.png';
import hauledOut from './../../img/Hauled_Out.png';
import newHomePort from './../../img/New_Home_Port_EBM.png';
import sunsetNewSlip from './../../img/Sunset_New_Slip.png';
import surveyAbove from './../../img/Survey_From_Above_Windward_Yacht_Center.png';
import surveyTime from './../../img/surveyTime.png';
import teamSiAndLis from './../../img/TeamSiAndLis.png';

const itemData = [
  {
    img: beautifulTeak,
    title: 'Beautiful Teak',
    author: '@irbinchic'
  },
  {
    img: backed_In,
    title: 'Backed In',
    author: '@irbinchic'
  },
  {
    img: capn_Ray_And_Crew,
    title: 'Delivery Captain & Crew',
    author: '@irbinchic'
  },
  {
    img: fuelDock,
    title: '/Fuel Dock',
    author: '@irbinchic'
  },
  {
    img: fullyBattened,
    title: 'Fully Battened Mainsl',
    author: '@irbinchic'
  },
  {
    img: hauledOut,
    title: 'On the Hard',
    author: '@irbinchic'
  },
  {
    img: newHomePort,
    title: 'New Home Port',
    author: '@irbinchic',
  },
  {
    img: sunsetNewSlip,
    title: 'Sunset in the New Slip',
    author: '@irbinchic'
  },
  {
    img: surveyAbove,
    title: 'Survey Time at Windward Yacht Center, Marina del Ray, CA',
    author: '@irbinchic'
  },
  {
    img: surveyTime,
    title: 'Survey Time',
    author: '@irbinchic'
  },
  {
    img: teamSiAndLis,
    title: 'Team Si & Lis',
    author: '@irbinchic'
  },
];

  export default itemData;